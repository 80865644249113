import app from "./app.module.css"
import {Card} from "react-bootstrap"
import caution from "./images/caution.png"
import React from "react"

export default function TronWebGuard({tronWeb}) {
  if (!tronWeb) {
    return (<Card className={`shadow mb-3 ${app.floatCard}`}>
      <Card.Body>
        <div className={app.container1}>
          <h4 className={app.fHeading}>TronWeb Not Detected</h4>
        </div>
        <div className={app.fline}></div>
        <p className={app.ftxt}>
          TronWeb is required for access to this dashboard
        </p>
        <div className={app.cImg}>
          <img width="50px" src={caution} alt=""/>
        </div>
        <p className={app.ftxt}>
          If you have TronWeb installed make sure you are logged in or have a
          wallet selected.
        </p>
      </Card.Body>
    </Card>)
  }

  return (null)
}



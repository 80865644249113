import React from 'react'
import {useAccordionToggle} from "react-bootstrap"
import fq from "../pages/faq.module.css"
const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log('totally custom!'),
  );
    return (
        <div>
             <button
      type="button"
      className={fq.btnToggle}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
        </div>
    )
}

export default CustomToggle

import React from 'react'
import {useAccordionToggle} from "react-bootstrap"
import fq from "../pages/faq.module.css"
const BoutiesToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log('totally custom!'),
  );
    return (
        <div>
            <div className={fq.tweetContainer}  onClick={decoratedOnClick}>
                <p className={fq.tweetTxt}>
                  Make a tweet promoting your referral address
                </p>
                <div className={fq.bnk}>5 BNKRX</div>
              </div>
        </div>
    )
}

export default BoutiesToggle

import React, {useEffect, useState} from "react"
import {Card, Form} from "react-bootstrap"
import cd from "./dashboard.module.css"
import appStyle from "../app.module.css"
import arrow from "../images/arrow.png"

const swapxAddress = 'TB4S2pvyX8uQsBPrTDWYCuSDfYSg6tMJm7'

const fastAddress = 'TNYMAeKiTPKDgeeAtD7hebneYYDUt9QdoY'
const bnkrxAddress = "TKSLNVrDjb7xCiAySZvjXB9SxxVFieZA7C"
const feeLimit = 150e6


const ExchangeTab = ({tronWeb}) => {
  const [switchFields, setSwitchFields] = useState(false)
  const [price, setPrice] = useState(0)
  const [supply, setSupply] = useState(0)
  const [volume, setVolume] = useState(0)

  const [amountTrx, setAmountTrx] = useState(0)
  const [amountBnkrx, setAmountBnkrx] = useState(0)

  const [trxBalance, setTrxBalance] = useState(0)
  const [bnkrxBalance, setBnkrxBalance] = useState(0)

  const [fromTrx, setFromTrx] = useState(true)

  useEffect(() => {
    const loadPrice = async () => {
      try {
        const swapx = await tronWeb.contract().at(swapxAddress)
        const priceSun = await swapx.getTokenToTrxInputPrice(1e6).call()

        setPrice(tronWeb.BigNumber(priceSun).dividedBy(1e6).toFixed(3))
      } catch (e) {
        console.error(e)
        setPrice(0)
      }
    }

    const loadSupply = async () => {
      try {
        const swapx = await tronWeb.contract().at(swapxAddress)
        const supply = await swapx.totalSupply().call()
        setSupply(tronWeb.BigNumber(supply).dividedBy(1e12).toNumber().toFixed(3))
      } catch (e) {
        console.error(e)
        setSupply(0)
      }
    }

    const loadWalletBalance = async () => {
      try {
        const {base58: currentAddress} = tronWeb.defaultAddress

        const fastContract = await tronWeb.contract().at(fastAddress)
        const bnkrx = await tronWeb.contract().at(bnkrxAddress)

        const userTrx = tronWeb.fromSun(await fastContract.balanceOf(currentAddress).call())
        const userBnkrx = tronWeb.fromSun(await bnkrx.balanceOf(currentAddress).call())

        setTrxBalance(userTrx)
        setBnkrxBalance(userBnkrx)
      } catch (e) {
        console.error(e)
      }
    }

    const loadVolume = async () => {
      try {
        const response = await fetch('https://bnkr-info.bankroll.network/volume/x/sun')
        const text = await response.text()
        const volume = tronWeb.BigNumber(text).dividedBy(1e12).toNumber().toFixed(3)
        setVolume(volume)
      } catch (e) {
        console.error(e)
        return 0
      }
    }

    if (!tronWeb) {
      return
    }

    loadPrice()
    loadSupply()
    loadVolume()
    loadWalletBalance()
  }, [tronWeb])


  const calculate = async () => {
    if (fromTrx) {
      const swapx = await tronWeb.contract().at(swapxAddress)
      const amount = Number.parseFloat(tronWeb.toSun(amountTrx))
      const estimatedSun = (await swapx.getTrxToTokenInputPrice(amount).call()).toNumber()

      const estimated = tronWeb.fromSun(estimatedSun)

      setAmountBnkrx(estimated)
    } else {
      const swapx = await tronWeb.contract().at(swapxAddress)
      const amount = Number.parseFloat(tronWeb.toSun(amountBnkrx))
      const estimatedSun = (await swapx.getTokenToTrxInputPrice(amount).call()).toNumber()

      const estimated = tronWeb.fromSun(estimatedSun)

      setAmountTrx(estimated)
    }
  }

  const exchange = async () => {
    const swapx = await tronWeb.contract().at(swapxAddress)
    if (fromTrx) {
      //buying bnkrx
      await swapx.trxToTokenSwapInput(1).send({callValue: tronWeb.toHex(tronWeb.toSun(amountTrx)), feeLimit: feeLimit})
    } else {
      // selling bnkrx
      await swapx.tokenToTrxSwapInput(tronWeb.toHex(tronWeb.toSun(amountBnkrx)), 1).send({
        callValue: 0,
        feeLimit: feeLimit
      })
    }
  }

  return (
    <div style={{marginTop: "35.8px"}}>
      <Card className={`shadow mb-1 ${cd.firstCard}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Card className={`shadow ${cd.secondCard}`}>
            <Card.Body className={`shadow ${cd.secondCardWrapper}`}>
              <div className={cd.container1}>
                <h4 className={cd.Heading}>Exchange</h4>
                <p className={cd.para1}>Swap BNKRX for TRX and vice-versa</p>
              </div>
              <div className={cd.line}></div>
              <div className={cd.container2}>
                <Form>
                  {switchFields ? (
                    <Form.Group
                      controlId="formBasicEmail"
                      style={{marginBottom: "1.3rem"}}
                    >
                      <div className={`${cd.labelContainer} mt-3`}>
                        <Form.Label className={cd.lbl}>From:</Form.Label>
                        <Form.Label className="mb-0">{trxBalance} TRX</Form.Label>
                      </div>
                      <div className={cd.inputContainer}>
                        <Form.Control
                          className={cd.inputs}
                          type="number"
                          autoComplete="off"
                          placeholder="0.00"
                          onChange={(e) => setAmountBnkrx(e.target.value.trim())}
                          onBlur={() => calculate()}
                          value={amountBnkrx}
                        />
                        <p className={cd.inputTxt}>BNKRX</p>
                      </div>
                    </Form.Group>
                  ) : (
                    <Form.Group
                      controlId="formBasicEmail"
                      style={{marginBottom: "1.3rem"}}
                    >
                      <div className={`${cd.labelContainer} mt-3`}>
                        <Form.Label className={cd.lbl}>From:</Form.Label>
                        <Form.Label className="mb-0">{bnkrxBalance} BNKRX</Form.Label>
                      </div>
                      <div className={cd.inputContainer}>
                        <Form.Control
                          className={cd.inputs}
                          type="number"
                          autoComplete="off"
                          placeholder="0.00"
                          onChange={(e) => setAmountTrx(e.target.value.trim())}
                          onBlur={() => calculate()}
                          value={amountTrx}
                        />
                        <p className={cd.inputTxt}>TRX</p>
                      </div>
                    </Form.Group>
                  )}


                  <div
                    className={cd.bgArrow}
                    onClick={() => {
                      setSwitchFields(!switchFields)
                      setFromTrx(!fromTrx)
                    }}
                  >
                    <img src={arrow} alt=""/>
                  </div>
                  {switchFields ? (
                    <Form.Group
                      controlId="formBasicEmail"
                      style={{marginBottom: "1.3rem"}}
                    >
                      <div className={`${cd.labelContainer} mt-3`}>
                        <Form.Label className={cd.lbl}>To:</Form.Label>
                        <Form.Label className="mb-0">{bnkrxBalance} BNKRX</Form.Label>
                      </div>
                      <div className={cd.inputContainer}>
                        <Form.Control
                          className={cd.inputs}
                          type="number"
                          autoComplete="off"
                          placeholder="0.00"
                          onChange={(e) => setAmountTrx(e.target.value.trim())}
                          value={amountTrx}
                          onBlur={() => calculate()}
                        />
                        <p className={cd.inputTxt}>TRX</p>
                      </div>
                    </Form.Group>
                  ) : (
                    <Form.Group
                      controlId="formBasicEmail"
                      style={{marginBottom: "1.3rem"}}
                    >
                      <div className={`${cd.labelContainer} mt-3`}>
                        <Form.Label className={cd.lbl}>To:</Form.Label>
                        <Form.Label className="mb-0">{trxBalance} TRX</Form.Label>
                      </div>
                      <div className={cd.inputContainer}>
                        <Form.Control
                          className={cd.inputs}
                          type="number"
                          autoComplete="off"
                          placeholder="0.00"
                          onChange={(e) => setAmountBnkrx(e.target.value.trim())}
                          onBlur={() => calculate()}
                          value={amountBnkrx}
                        />
                        <p className={cd.inputTxt}>BNKRX</p>
                      </div>
                    </Form.Group>
                  )}

                  <div
                    className={`${appStyle.btnContainer} mb-0 mx-0`}
                    style={{alignItems: "center", marginTop: "1em"}}
                  >
                    <button
                      style={{
                        width: "100%",
                        fontSize: "23px",
                        padding: "5px 16px",
                        borderRadius: "15px",
                      }}
                      className={appStyle.btn}
                      type={"button"}
                      onClick={() => exchange()}
                    >
                      Exchange
                    </button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
          <Card.Body className={cd.pContainer}>
            <div className={cd.pContainer1}>
              <div className={cd.dashContainer}>
                <h4 className={cd.dashPrice}>Price</h4>
                <p className={cd.dashPNum}>{price}</p>
              </div>
            </div>
            <div className={`${cd.pContainer2} mt-0`}>
              <h6 className={cd.dashHeading}>Liquidity</h6>
              <h5 className={cd.dashNum}>{supply} M</h5>
            </div>
            <div className={`${cd.pContainer2} mt-0`}>
              <h6 className={cd.dashHeading}>Volume</h6>
              <h5 className={cd.dashNum}>{volume} M</h5>
            </div>
          </Card.Body>
        </div>
      </Card>
    </div>
  )
}

export default ExchangeTab

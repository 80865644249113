import { Tab, Tabs } from 'react-bootstrap';
import app from '../app.module.css';
import ExchangeTab from '../components/ExchangeTab';
import JoinTab from '../components/JoinTab';
import Swape from '../components/Swape';
import React, { useState } from 'react';
import DashboardTab from '../components/DashboardTab';

const Dashboard = ({ tronWeb }) => {
  const [key, setKey] = useState("dashboard")

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="exchange" title="Exchange">
        <div className={app.tabContainer}>
          <ExchangeTab tronWeb={tronWeb}/>
        </div>
      </Tab>
      <Tab eventKey="dashboard" title="Dashboard">
        <div className={app.tabContainer}>
          <DashboardTab tronWeb={tronWeb}/>
        </div>
      </Tab>
      <Tab eventKey="join" title="Join">
        <div className={app.tabContainer}>
          <JoinTab tronWeb={tronWeb}/>
        </div>
      </Tab>
      <Tab eventKey="swap" title="Swap">
        <div className={app.tabContainer}>
          <Swape/>
        </div>
      </Tab>
    </Tabs>
  )
}

export default Dashboard;

import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/theleet.png";
import faq from "../pages/faq.module.css";
import header from "./header.module.css";
const Header = ({ activColor }) => {
  return (
      <div className={header.container}>
        <img src={logo} alt=""/>
        <div>
          <NavLink className={faq.navItem} to="/dashboard" activeClassName={header.selected}>
            Dashboard
          </NavLink>
          <NavLink className={faq.navItem} to="/bouties" activeClassName={header.selected}>
            Bouties
          </NavLink>
          <NavLink className={faq.navItem} to="/home" activeClassName={header.selected}>
            Home
          </NavLink>
          <NavLink className={faq.navItem} to="/blog" activeClassName={header.selected}>
            Blog
          </NavLink>
          <NavLink className={faq.navItem} to="/faq" activeClassName={header.selected}>
            FAQ
          </NavLink>
          <NavLink className={faq.navItem} to="/contact" activeClassName={header.selected}>
            Contact
          </NavLink>
        </div>
      </div>
  );
};

export default Header;

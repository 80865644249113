import React from 'react';
import fq from "./faq.module.css";
import { Accordion, Card } from 'react-bootstrap';
import CustomToggle from '../components/CustomToggle';

const Faq = () => {
  return (
    <div>
      <p className={fq.headingName}>FAQ</p>
      <div style={{marginTop:"20px", marginBottom:"4em"}}>
        <Card className={`shadow mb-1 ${fq.cardContainer}`}>
          <Card.Body>
            <p className={fq.subHeading}>Frequently Asked Questions</p>
            <p className={fq.askQuestion}>
              If you don’t find your question ask us{" "}
              <a style={{ color: "#72326C" }} href="!#">
                here
              </a>{" "}
            </p>
            <div
              className={`d-flex justify-content-center ${fq.scrollbarColor} ${fq.scrollbar}`}
            >
              <Accordion defaultActiveKey="1">
                <CustomToggle eventKey="0">
                  How to Setup your desktop wallet
                </CustomToggle>

                <Accordion.Collapse eventKey="0">
                  <div className={fq.innerAccordionContainer}>
                    <p className={fq.innerAccordionHeading}>
                      How to Setup your desktop wallet
                    </p>
                    <p className={fq.innerAccidionPara}>
                      Visit TronLink Official Website and select your
                      Operating System and begin the download. Click the
                      TronLink extension icon pinned on the toolbar to open
                      the extension and setup your TronLink wallet. {">"}{" "}
                      Click on Create account. {">"} Set up a Wallet Name{" "}
                      {">"} Set up a password. {">"} Copy down or Back up your
                      12–24 words Mnemonic. {">"} Done
                    </p>
                  </div>
                </Accordion.Collapse>

                <CustomToggle eventKey="1">Get BNKRX</CustomToggle>

                <Accordion.Collapse eventKey="1">
                  <div className={fq.innerAccordionContainer}>
                    <p className={fq.innerAccordionHeading}>
                      How to Setup your desktop wallet
                    </p>
                    <p className={fq.innerAccidionPara}>
                      The recommended exchange for trading BNKRX is SwapX, as
                      it provides the lowest prices and highest liquidity,
                      resulting in less slippage for larger trades.
                    </p>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Faq;

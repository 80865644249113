import fq from "./faq.module.css";
import { Accordion, Card, Form } from 'react-bootstrap';
import { useState } from 'react';
import BoutiesToggle from '../components/BoutiesToggle';

const Bouties = () => {
  const [toggle, setToggle] = useState(false);
  const [link, setLink] = useState("")
  const [wallet, setWallet] = useState("")

  return (
    <div>
        <p className={fq.headingName} style={{marginBottom:"13px"}}>Bouties</p>
        <div style={{ marginTop: "20px", marginBottom: "4em" }}>
          <Card className={`shadow mb-1 ${fq.cardContainer}`}>
            <Card.Body>
              <p className={fq.subHeading}>
                Completing a certain task will reward you with extra tokens!
              </p>
              <div
                className={` d-flex  justify-content-center ${fq.scrollbarColor} ${fq.scrollbar}`}
              >
                <Accordion defaultActiveKey="">
                  <BoutiesToggle eventKey="0" />
                  <Accordion.Collapse eventKey="0">
                    <div
                      className={fq.bankContainer}
                      style={{ position: "relative" }}
                    >
                      <div className={fq.tweetTxt}>
                        Make a tweet promoting your referral address
                        <p className={fq.bankPara}>
                          Create or learn to create a fun bankroll gif and
                          submit to approval!
                        </p>
                        {toggle === false?(
                          <Accordion.Toggle onClick={() => setToggle(true)} variant="link" eventKey="0" className={fq.btnSub}>SUBMIT</Accordion.Toggle>
                        ):(<Accordion.Toggle onClick={() => setToggle(false)} variant="link" eventKey="0" className={fq.btnSub}>SUBMIT</Accordion.Toggle>)}
                      </div>

                      <div className={fq.bnk}>5 BNKRX</div>

                      {toggle === true ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "45px",
                            left: "29px",
                          }}
                        >
                          <Form className={fq.subForm} onSubmit={(e) => {
                            e.preventDefault()
                            fetch('/api/bounty', {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              body: JSON.stringify({link, wallet})
                            }).then(() => console.log('success')).catch(console.error)
                          }}>
                            <Form.Group
                              controlId="formBasicEmail"
                              className="mb-0"
                            >
                              <div className={fq.binputContainer}>
                                <p className={fq.lbl}>Link:</p>
                                <Form.Control
                                  className={fq.binputs}
                                  type="text"
                                  autoComplete="off"
                                  onChange={e => setLink(e.target.value.trim())}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group
                              controlId="formBasicEmail"
                              className="mb-0"
                            >
                              <div className={fq.binputContainer}>
                                <p className={fq.lbl}>Wallet</p>
                                <Form.Control
                                  className={fq.binputs}
                                  type="text"
                                  autoComplete="off"
                                  onChange={e => setWallet(e.target.value.trim())}
                                />
                              </div>
                            </Form.Group>
                            <button className={fq.btnSend}>send</button>
                          </Form>
                        </div>
                      ) : null}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            </Card.Body>
          </Card>
        </div>
    </div>
  )
}

export default Bouties;

import React from "react"
import app from "../src/app.module.css"

function ConnectButton({tronWeb, setTronWeb}) {
  if (tronWeb) {
    console.log(tronWeb)
    const {base58} = tronWeb.defaultAddress

    if (base58) {
      return (
        <div className={app.btnContainer}>
          <button className={app.btnTruncate}>{base58}</button>
        </div>)
    }
  }

  return (
    <div className={app.btnContainer}>
      <button className={app.btn}
              onClick={() => window.tronWeb && window.tronWeb.defaultAddress.base58 && setTronWeb(window.tronWeb)}>Connect
      </button>
    </div>)


}

export default ConnectButton

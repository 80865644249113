import React from 'react'
import { Card } from "react-bootstrap";
import cd from "./dashboard.module.css";
const Swape = () => {
  const demos = {
    swap:
      '<iframe id="simpleswap-frame" name="SimpleSwap Widget" width="510px" height="370px" src="https://simpleswap.io/widget/ecdd94f4-2178-42bf-beda-5973312a4189" frameborder="0"></iframe>',
  };

  function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }

    return (
        <div style={{marginTop:"35.8px"}}>
      <Card className={`shadow mb-1 ${cd.firstCardS}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Card.Body className={`${cd.pContainer} m-0`}>
          <Iframe iframe={demos["swap"]} />,
          </Card.Body>
        </div>
      </Card>
    </div>
    )
}

export default Swape

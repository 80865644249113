import app from "./app.module.css"
import React, {useEffect, useState} from "react"
import {Container} from "react-bootstrap"
import ConnectButton from "./ConnectButton"
import TronWebGuard from "./TronWebGuard"
import Header from './components/Header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Bouties from './pages/Bouties';
import Blog from './pages/Blog';
import Faq from './pages/Faq';
import Contact from './pages/Contact';

function App() {
  const [tronWeb, setTronWeb] = useState(null)

  const injectTronLink = async () => {
    const maxTries = 3
    let step = 0

    window.addEventListener('message', function () {
      setTronWeb(window.tronWeb)
    })

    while(step < maxTries) {
      if(window.tronWeb && window.tronWeb.defaultAddress.base58) {
        setTronWeb(window.tronWeb)
        break
      }

      await new Promise(r => setTimeout(r, 1000));

      step++
    }
  }

  useEffect(() => {
    injectTronLink()
  }, [])

  return (
    <Router>
      <TronWebGuard tronWeb={tronWeb}/>
      <Header activColor="Dashboard" />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Container disabled={!!tronWeb} className={app.innerContainer} style={{
          opacity: `${tronWeb ? 1 : 0.5}`,
          cursor: `${tronWeb ? 'default' : 'not-allowed'}`
        }}>
          <ConnectButton tronWeb={tronWeb} setTronWeb={setTronWeb}/>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/home">
              <Home/>
            </Route>
            <Route path="/dashboard">
              <Dashboard tronWeb={tronWeb}/>
            </Route>
            <Route path="/bouties">
              <Bouties/>
            </Route>
            <Route path="/blog">
              <Blog/>
            </Route>
            <Route path="/faq">
              <Faq/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
          </Switch>
          <span className={`mt-3 ${app.subText}`}>
            The Fleet is not, in any way, associated with{" "}
            <span style={{ color: "#86367e" }}>Bankroll</span> or its creater
          </span>
        </Container>
      </Container>
    </Router>
  )
}

export default App

import React, {useEffect, useState} from "react"
import {Card, Row, Col, Form} from "react-bootstrap"
import cd from "./dashboard.module.css"
import { PieChart } from 'react-minimal-pie-chart';

const zeroAddress = 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
const contractAddress = "TUBA2HhtN7xxEZ8mYwAk26kNEfwKVcSJNS"
const bnkrxAddress = "TKSLNVrDjb7xCiAySZvjXB9SxxVFieZA7C"
const buddyAddress = 'TLiPH8Z9xUK57hxhuYvXrZQATZovFq7kfQ'
var custodyAddress = 'TPmqRz2HmrUDVDRgGxhZv4yAKBRWsRex4E'
const feeLimit = 150e6

const DashboardTab = ({tronWeb}) => {

  const [available, setAvailable] = useState(0)
  const [deposits, setDeposits] = useState(0)
  const [rewards, setRewards] = useState(0)
  const [, setPlayersDirect] = useState(0)
  const [, setPlayersTotal] = useState(0)

  const [depositAmount, setDepositAmount] = useState(0)

  const [totalPayouts, setTotalPayouts] = useState(0)

  const isStakeEnabled = async () => {
    const {base58: currentAddress} = tronWeb.defaultAddress
    const bnkrx = await tronWeb.contract().at(bnkrxAddress)

    let allowance = await bnkrx.allowance(currentAddress, contractAddress).call()

    if (allowance['remaining'] && allowance['remaining']['_hex'] != null) {
      allowance = tronWeb.toBigNumber(allowance.remaining._hex)
    }
    let balance = (await bnkrx.balanceOf(currentAddress).call())
    return allowance.gte(balance)
  }

  const deposit = async (amount) => {
    const {base58: currentAddress} = tronWeb.defaultAddress

    const flow = await tronWeb.contract().at(contractAddress)
    const buddy = await tronWeb.contract().at(buddyAddress)
    const bnkrx = await tronWeb.contract().at(bnkrxAddress)
    let userInfo = await flow.userInfo(currentAddress).call()
    const currentInfo = userInfo

    let ref = tronWeb.address.fromHex(userInfo.upline)
    const myBuddy = tronWeb.address.fromHex(await buddy.myBuddy().call())
    ref = (ref !== zeroAddress) ? ref : myBuddy

    if (ref === zeroAddress) {
      return console.error('NO BUDDY!!! You need to have a buddy! Scroll down and add your referral or support marketing/development. Thanks')
    }

    userInfo = await flow.userInfo(ref).call()

    if (userInfo.deposit_time.toNumber() === 0 && currentAddress !== custodyAddress) {
      return console.error(`BAD BUDDY!!! Your buddy hasn't joined yet, however you can still get started TODAY! Scroll down and support marketing/development. 
        You EARN by getting YOUR OWN direct referrals, so all good!`)
    }

    let isStaking = await isStakeEnabled()

    if (!isStaking) {
      return console.error('Enable Deposits', 'Deposits are not enabled.  Look for the toggle and make sure it is on (purple)!')
    }
    let pay = await flow.payoutOf(currentAddress).call()

    if (pay.payout.toNumber() > 5e5) {
      let tx = await flow.roll().send({callValue: 0, feeLimit: feeLimit})
      console.log('roll', tx)
    }

    if (amount < 1 || !isFinite(amount) || amount === '') {
      return console.error('Invalid amount')
    }

    if (currentInfo.deposit_time.toNumber() === 0 && amount < 10) {
      return console.error('Initial Deposit!!!', 'You need to deposit a minimum of 10 BNKRX to start.  Thanks')
    }

    let balance = await bnkrx.balanceOf(currentAddress).call()
    amount = tronWeb.toBigNumber(amount * Math.pow(10, 6))

    //The solution to the decimals bug
    console.log(balance.toString(10), amount.toString(10), amount.gt(balance))
    amount = (amount.gt(balance)) ? balance : amount

    let amount_hex = `0x${tronWeb.toBigNumber(amount).toString(16)}`
    console.log('calctokens', amount, amount_hex)

    await flow.deposit(ref, amount_hex).send({callValue: 0, feeLimit: feeLimit})
  }

  const claim = async () => {
    const {base58: currentAddress} = tronWeb.defaultAddress
    const flow = await tronWeb.contract().at(contractAddress)

    const pay = await flow.payoutOf(currentAddress).call()

    if (!pay.payout.toNumber()) {
      console.error('NO DIVS!!!', 'Slow down there buddy, you need to have some divs first! Make a deposit')
    }

    await flow.claim().send({callValue: 0, feeLimit: feeLimit})
  }

  async function roll() {
    const {base58: currentAddress} = tronWeb.defaultAddress
    const flow = await tronWeb.contract().at(contractAddress)

    let pay = await flow.payoutOf(currentAddress).call()

    if (!pay.payout.toNumber()) {
      return console.error('NO DIVS!!!', 'Slow down there buddy, you need to have some divs first! Make a deposit')
    }

    await flow.roll().send({callValue: 0, feeLimit: feeLimit})
  }


  useEffect(() => {

    const loadInfo = async () => {
      try {
        const {base58: currentAddress} = tronWeb.defaultAddress
        //const currentAddress = "TJZa71bBvBuXjxSAzzLnhgXR2a2swVq9tK"

        const flow = await tronWeb.contract().at(contractAddress)
        const bnkrx = await tronWeb.contract().at(bnkrxAddress)

        let infoTotals = await flow.userInfoTotals(currentAddress).call()

        setTotalPayouts(infoTotals.total_payouts.toNumber())

        let info = await flow.userInfo(currentAddress).call()
        let pay = await flow.payoutOf(currentAddress).call()

        pay.payout = pay.payout.toNumber()
        pay.max_payout = pay.max_payout.toNumber()

        const userBalance = tronWeb.fromSun((await bnkrx.balanceOf(currentAddress).call()).toNumber())

        setDeposits(tronWeb.BigNumber(userBalance).dividedBy(1e6).toFixed(3).toString())
        setAvailable(tronWeb.BigNumber(pay.payout).dividedBy(1e6).toFixed(3).toString())

        const rewards = tronWeb.BigNumber(info.direct_bonus).plus(tronWeb.BigNumber(info.match_bonus))

        setRewards(rewards.dividedBy(1e6).toFixed(3).toString())
        setPlayersDirect(infoTotals.referrals.toNumber())
        setPlayersTotal(infoTotals.total_structure.toNumber())
      } catch (e) {
        console.error(e)
      }
    }

    if (!tronWeb) {
      return
    }
    loadInfo()
  }, [tronWeb])

  return (
    <div style={{marginTop: "10px"}}>
      <p className={cd.outCardTxt1}>Price: 12.702 TRX</p>
      <Card className={`shadow mb-1 ${cd.firstCard}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Card className={`shadow ${cd.secondCard}`}>
            <Card.Body style={{padding: "10px"}}>
              <div className={cd.container1}>
                <h4 className={cd.Heading}>Dashboard</h4>
                <p className={cd.para1}>See your Flow stats</p>
              </div>
              <div className={cd.line}></div>
              <Row style={{marginLeft: "1em"}}>
                <Col lg="6" xl="6">
                  <div className={`${cd.dContainer2} mt-0`}>
                    <h6 className={cd.dHeading}>Available</h6>
                    <h5 className={cd.dNum}>{available}</h5>
                  </div>
                </Col>
                <Col lg="6" xl="6">
                  <div className={`${cd.dContainer2} mt-0`}>
                    <h6 className={cd.dHeading}>Deposits</h6>
                    <h5 className={cd.dNum}>{deposits}</h5>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  alignItems: "flex-end",
                  height: "20vh",
                  marginLeft: "1em",
                }}
              >
                <Col lg="6" xl="6">
                  <div className={`${cd.dContainer2} mt-0`}>
                    <h6 className={cd.dHeading}>Rewards</h6>
                    <h5 className={cd.dNum}>{rewards}</h5>
                  </div>
                </Col>
                <Col lg="6" xl="6">
                  <div className={`${cd.dContainer2} mt-0`}>
                    {totalPayouts === 0 ? <PieChart
                      data={[
                        { title: 'One', value: 100 , color: '#CBC9C9' },
                      ]}
                      label={() => 0 + '%'}
                      labelPosition={0}
                      labelStyle={{
                        fontSize: '23px',
                        fontWeight: '700',
                        fontFamily: 'sans-serif',
                        fill: '#CBC9C9'
                      }}
                      lineWidth={15}
                      totalValue={100}
                    /> :
                      <PieChart
                        data={[
                          { title: 'One', value: totalPayouts , color: '#1DBF73' },
                        ]}
                        label={({ dataEntry }) => dataEntry.value + '%'}
                        labelPosition={0}
                        labelStyle={{
                          fontSize: '23px',
                          fontWeight: '700',
                          fontFamily: 'sans-serif',
                          fill: '#1DBF73'
                        }}
                        lineWidth={15}
                        totalValue={100}
                      />}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card.Body className={cd.pdContainer}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}>
              <p className={cd.dDeposit}>Deposits</p>
              <div className={cd.circle}>Claim 3 BNKRX</div>
            </div>

            <div className={`${cd.labelContainer} mt-3`}>
              <Form.Label className={cd.lblD}>Amount:</Form.Label>

              <Form.Label className={cd.lblD}>4458 BNKRX</Form.Label>
            </div>
            <Form>
              <Form.Group
                controlId="formBasicEmail"
                style={{marginBottom: "1.3rem"}}
              >
                <div className={cd.dinputContainer}>
                  <Form.Control
                    className={cd.dinputs}
                    type="text"
                    onChange={(e)=> setDepositAmount(e.target.value.trim())}
                    placeholder="BNKRX"
                  />
                  <button className={cd.btnDeposit} type={"button"}
                          onClick={(e) => deposit(Number(depositAmount))}>Deposit
                  </button>
                </div>
              </Form.Group>
            </Form>
            <button className={cd.btnClaim} onClick={() => roll()}>Roll</button>
            <button className={cd.btnClaim} onClick={() => claim()}>Claim</button>
            <p className={cd.outCardTxt1}>
              Price: <span style={{ color: "#1DBF73" }}> 12.702 TRX </span>
            </p>
          </Card.Body>
        </div>
      </Card>
    </div>
  )
}

export default DashboardTab

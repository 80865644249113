import React from "react";
import { Card, Form } from "react-bootstrap";
import cd from "./dashboard.module.css";
import RevealButton from "./RevealButton"

const JoinTab = ({tronWeb}) => {
  return (
    <div style={{marginTop:"35.8px"}}>
      <Card className={`shadow mb-1 ${cd.firstCard}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Card className={`shadow ${cd.secondCard}`}>
            <Card.Body style={{padding: "10px"}}>
              <div className={cd.container1}>
                <h4 className={cd.Heading}>Join</h4>
                <p className={cd.para1}>
                  Follow the instruction to join The Fleet{" "}
                </p>
              </div>
              <div className={cd.line}></div>
              <div className={cd.container2}>
                <p className={cd.para2}>
                  This tool finds you a place in the chain
                </p>
                <p className={cd.para3}>you will get referrals automatically</p>
              </div>
              <div className={cd.container4}>
                <Form>
                  <Form.Group controlId="formBasicEmail" className="mb-0">
                    <p className={cd.lblMainJ}>Buy <span style={{color:"#1DBF73"}}>BNKRX</span></p>

                    <p className={cd.lblJ}>4458 TRX</p>

                    <div className="d-flex align-items-center">
                      <div className={cd.jinputContainer}>
                        <Form.Control
                          className={cd.jinputs}
                          type="text"
                          autoComplete="off"
                        />
                        <p className={cd.innerBuyLbl}>TRX</p>
                        <button className={cd.btnBuy} style={{width:"7em"}}>Buy</button>
                      </div>
                      <p className={cd.rightLbl}> 400 BNKRX</p>
                    </div>
                    <p className={cd.lblPrice}>Price: <span style={{color:"#1DBF73"}}> 12.702 TRX</span></p>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail" className="mb-0" >
                    <p className={`${cd.lblMainJ} mb-2`}>Update Buddy</p>

                    <div className={`${cd.jinputContainer}`} style={{background:"rgba(35, 35, 35, 0.5)", width:"17em"}}>
                      <Form.Control
                        className={cd.jinputs}
                        type="text"
                        placeholder=""
                        autoComplete="off"
                      />
                      <button className={cd.btnDeJo}>Join the Fleet</button>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail" className="mb-0">
                    <p className={cd.lblMainJ}>Deposit</p>

                    <p className={cd.lblJ}>4458 BNKRX</p>

                    <div className={cd.jinputContainer} style={{background:"rgba(35, 35, 35, 0.5)"}}>
                      <Form.Control
                        className={cd.jinputs}
                        type="text"
                        placeholder=""
                        autoComplete="off"
                      />
                      <button className={cd.btnDeJo} style={{width:"8em"}}>Deposit</button>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </Card.Body>
          </Card>
          <Card.Body className={cd.pContainer}>
            <div className={cd.pContainer1}>
              <h4 style={{ color: "#F1F1F1" }} className={cd.Heading}>
                How it works
              </h4>
              <p style={{ color: "#F1F1F1", fontWeight: "500", fontSize:"14px", marginTop:"2.5em" }}>
                By using the ‘Find a Buddy’ Tool, The Fleet becomes an optimized
                15+ wide by 3 down team
              </p>
            </div>
            <div className={cd.pContainer2}>
              <h5 className={cd.para1} style={{ fontSize: "1em" }}>
                Use this upline
              </h5>
              <RevealButton tronWeb={tronWeb}/>
            </div>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

export default JoinTab;

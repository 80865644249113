import cd from "./dashboard.module.css"
import React, {useState} from "react"

const contractAddress = "TUBA2HhtN7xxEZ8mYwAk26kNEfwKVcSJNS"

export default function RevealButton({tronWeb}) {
  const [pending, setPending] = useState(false)
  const [buddy, setBuddy] = useState(null)

  const handleReveal = async () => {
    try {
      setPending(true)
      const buddy = await revealBuddy("TJZa71bBvBuXjxSAzzLnhgXR2a2swVq9tK")
      setBuddy(buddy)
    } catch (e) {
      console.error(e)
    } finally {
      setPending(false)
    }
  }

  const revealBuddy = async (address) => {
    const current = await getPlayer(address)

    if (current.directs < 15) {
      throw new Error("Lead does not have full roster")
    }

    const org = await getOrg(address)

    const result = await pluckBuddy(org, 2, 0, null)

    if (!result.children || !Object.keys(result.children).length) {
      throw new Error("Empty result")
    }

    const children = Object.keys(result.children).map(address => result.children[address]).filter(child => child.directs === 0)

    if (!children.length) {
      throw new Error("No maxed out directs")
    }

    const sorted = children.sort((a, b) => b.deposits - a.deposits)

    const [suggested] = sorted

    return suggested
  }

  const getPlayer = async (player) => {
    const flow = await tronWeb.contract().at(contractAddress)

    const info = await flow.userInfoTotals(player).call()

    const deposits = info.total_deposits.toNumber()
    const payouts = info.total_payouts.toNumber()
    const directs = info.referrals.toNumber()

    return {player, directs, deposits: Math.floor((deposits - payouts) / 1e6)}
  }

  const getOrg = async (address) => {
    const response = await fetch(`https://flowbot-dot-bankroll-5a78d.uc.r.appspot.com/org/${address}`)

    return response.json()
  }

  const pluckBuddy = async (player, depth, directs, result) => {
    if (result == null) {
      result = {parent: Object.keys(player)[0], max_depth: depth, children: {}, raw_count: 0, total_count: 0}
      depth = 0
    }

    if (depth < result.max_depth) {
      depth++
      let current = Object.keys(player)[0]
      let children = Object.keys(player[current])

      if (children.length) {
        result.total_count += children.length

        let child

        for (let i = 0; i < children.length; i++) {

          result.raw_count++

          //Only fill if directs are viable
          let child_directs = Object.keys(player[current][children[i]]).length

          if (child_directs) {
            child = {player: children[i], directs: child_directs, deposits: 0}
          } else {
            child = await getPlayer(children[i])
          }

          child.upline = current
          result.children[child.player] = child

          //Walk this child's children; make a synthetic top level object
          let next = {}
          next[children[i]] = player[current][children[i]]
          await pluckBuddy(next, depth, directs, result)
        }

      }
    } else {
      return result
    }

    return result
  }

  if (buddy) {
    return <button className={cd.butnRevealTrunc} onClick={() => navigator.clipboard.writeText(buddy.player)} title={"Copy"}>{buddy.player}</button>
  }

  return <button disabled={!tronWeb || pending} className={cd.butnReveal} onClick={handleReveal}>{ pending ? "Loading ..." : "Reveal"}</button>
}
